import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() =>
  import("./containers/TheLayout").catch((e) => window.location.reload())
);

// Pages
const Login = React.lazy(() =>
  import("./views/pages/login/Login").catch((e) => window.location.reload())
);
const Register = React.lazy(() =>
  import("./views/pages/register/Register").catch((e) =>
    window.location.reload()
  )
);
const Page404 = React.lazy(() =>
  import("./views/pages/page404/Page404").catch((e) => window.location.reload())
);
const Page500 = React.lazy(() =>
  import("./views/pages/page500/Page500").catch((e) => window.location.reload())
);

class App extends Component {
  render() {
    return (
      <Router>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/dashboard"
              name="Dashboard Page"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/dashboard-detail/:id"
              name="Dashboard Detail"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/bidangkerja"
              name="Bidang Kerja"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/bidangkerja-detail/:id"
              name="Bidang Kerja Detail"
              render={(props) => <TheLayout {...props} />}
            />
             <Route
              exact
              path="/faq"
              name="FAQ"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/faq-detail/:id"
              name="FAQ Detail"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/kompetensi"
              name="Kompetensi"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/kompetensi-detail/:id"
              name="Kompetensi Detail"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/artikel"
              name="Artikel"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/artikel-detail/:id"
              name="Artikel Detail"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/banner"
              name="Banner"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/poster"
              name="Poster"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/user"
              name="User"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/banner-detail/:id"
              name="Banner Detail"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/konsorsium"
              name="Konsorsium"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/konsorsium-detail/:id"
              name="Konsorsium Detail"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/kontak"
              name="Kontak"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/kontak-detail/:id"
              name="Kontak Detail"
              render={(props) => <TheLayout {...props} />}
            />
             <Route
              exact
              path="/sumberDaya"
              name="Sumber Daya"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/sumberDaya-detail/:id"
              name="Sumber Daya Detail"
              render={(props) => <TheLayout {...props} />}
              />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
             <Route
              exact
              path="/emailAdmin"
              name="Email Admin"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;
